<template>
  <section class="portfolio">
    <h1>MES PROJETS</h1>
    <div class="projects-container">
      <router-link v-for="(project, index) in this.$store.state.allProjects" :to="'/project/'+project.id" :key="index">
        <span>{{ project.title }}</span>
        <img :src="require(`@/assets/project_${project.id}.webp`)" alt="miniature projet">
      </router-link>
    </div>
  </section>
</template>

<script>
export default{
  name: 'portfolioView',
  methods: {
    scrollTop(duration){
      const scrollStep = -window.scrollY / (duration / 15);
      const scrollInterval = setInterval(function () {
        if (window.scrollY !== 0) {
          window.scrollBy(0, scrollStep);
        } else {
          clearInterval(scrollInterval);
        }
      }, 15);
    }
  },
  mounted() {
    this.scrollTop(250);
  },
}
</script>

<style lang="scss" scoped>
  h1{
    margin-bottom: 75px;
  }

  .projects-container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 5vw;
    width: 80vw;
    margin: 0 auto;
    a{
      width: 45%;
      transition: .4s;
      overflow: hidden;
      border-radius: 15px;
      position: relative;
      &:hover{
        transform: translateY(-5px);
        span {
          transform: translateY(0);
        }
      }
      span{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 10px 0;
        background-color: rgba(235, 251, 255, .8);
        text-align: center;
        text-transform: uppercase;
        color: var(--dark-color);
        font-weight: 800;
        z-index: 50;
        transform: translateY(-100%);
        transition: .4s;
      }
      img{
        max-width: 100%;
        border-radius: 15px;
      }
    }
    
    @media screen and (max-width: 1200px){
      flex-direction: column;
      gap: 5vh;
      width: 90vw;
      a{
        width: 80%;
        img{
          min-width: 100%;
        }
      }
    }
    @media screen and (max-width: 1024px){
      width: 100vw;
      max-width: 100%;
    }
    @media screen and (max-width: 730px){
      a {
        &:hover{
          transform: translateY(0);
        }
        span{
          position: static;
          display: block;
          transform: translateY(0);
        }
        img{
          border-radius: 0 0 15px 15px;
        }
      }
    }
    
    @media screen and (max-width: 480px){
      a{
        width: 90%;
        span{
          font-size: 0.8em;
        }
      }
    }
  }
</style>