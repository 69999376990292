<template>
  <section class="projects">
    <h1>{{ actualProject.title }}</h1>
    <img v-if="actualProject.id == 4 || actualProject.id == 5" :src="require(`@/assets/project_${actualProject.id}.webp`)" alt="miniature projet"/>
    <video v-else :src="require(`@/assets/videos/project_${actualProject.id}.mp4`)" autoplay loop muted controls controlslist="nodownload" :poster="require(`@/assets/project_${actualProject.id}.webp`)"></video>


    <div class="labels">
      <h2>Temporalité :</h2>
      <div class="timing">
        <div :class="`label ${actualProject.timing}`">{{ actualProject.timing }}</div>
      </div>
      <h2>Technologies utilisées :</h2>
      <div class="technos">
        <div v-for="(label, index) in actualProject.labels" :key="index" class="techno">
          <!-- SI LA TECHNO NE CONTIENT PAS DE . -->
          <div v-if="!label.includes('.') && !label.includes('#')" :class="`label ${label}`">{{label}}</div>
          <!-- SI LA TECHNO CONTIENT UN . -->
          <div v-if="label.includes('.')" :class="`label ${label.replace('.', '')}`">{{label}}</div>
          <!-- SI LA TECHNO CONTIENT UN # -->
          <div v-if="label.includes('#')" :class="`label ${label.replace('#', 'S')}`">{{label}}</div>
        </div>
      </div>
      <h2>Responsive :</h2>
      <div class="responsive">
        <div v-if="actualProject.responsive" class="label is-responsive">Responsive</div>
        <div v-if="actualProject.responsive" class="label mobile-label is-responsive">Oui</div>
        <div v-if="!actualProject.responsive" class="label mobile-label isnt-responsive">Non</div>
      </div>
    </div>

    <div class="project-description"></div>

    <button class="cta" @click="this.$router.push('/portfolio')">Retour aux projets</button>
  </section>
</template>


<script>
import { mapState } from 'vuex';

export default {
  name: 'PortfolioView',
  methods: {
    fetchData(){
      // console.log(parseInt(this.$route.params.id));
      let project_id = parseInt(this.$route.params.id);
      this.$store.commit('getProject', project_id);
    },
    scrollTop(duration){
      const scrollStep = -window.scrollY / (duration / 15);
      const scrollInterval = setInterval(function () {
        if (window.scrollY !== 0) {
          window.scrollBy(0, scrollStep);
        } else {
          clearInterval(scrollInterval);
        }
      }, 15);
    }
  },
  computed: {
    ...mapState(['actualProject'])
  },
  mounted() {
    this.scrollTop(300);
    document.querySelector('.project-description').innerHTML = this.actualProject.description;
  },
  created(){
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  }
}
</script>

<style lang="scss" scoped>
  .labels,
  img,
  .project-description{
    width: 80vw;
    font-size: 1.2em;
  }

  img{
    border-radius: 25px;
  }
  
  video{
    width: 80vw;
    border-radius: 25px;
    &::after{
      content: "";
      display: block;
      width: 40px;
      height: 40px;
    }
  }
  h1{
    text-transform: uppercase;
  }
  .labels{
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2{
      display: none;
    }
    .label{
      padding: 8px 30px;
      border-radius: 50px;
      background-color: var(--cta-color);
      color: var(--black-color);
      &.isnt-responsive,
      &.is-responsive{
        color: var(--light-color);
      }
      &.mobile-label{
        display: none;
      }
      &.A.faire{
      background-color: #B0B0B0;
      }
      &.En.cours{
      background-color: #EAA622;
      }
      &.Gsap,
      &.Terminé{
      background-color: #1FCC5A;
      }
      &.Vuejs{
      background-color: #00CC8B;
      }
      &.Scss{
      background-color: #FFA3D4;
      }
      &.Axios{
      background-color: #F09EFF;
      }
      &.Nodejs{
      background-color: #82C17B;
      }
      &.Sequelize{
      background-color: #A4B2D1;
      }
      &.Figma{
        background-color: #FF9999;
      }
      &.XML,
      &.HTML{
        background-color: #FFA070;
      }
      &.NET,
      &.CSS{
        background-color: #A8B4FF;
      }
      &.JS{
        background-color: #FFFF5D;
      }
      &.CS,
      &.PHP{
        background-color: #B6A3FF;
      }
      &.MySQL{
        background-color: #DD99FF;
      }
      &.Wordpress{
      background-color: var(--light-color);
      }
      &.isnt-responsive,
      &.is-responsive{
        background-color: #0c1920;
      }
    }
    .technos{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: .5vw;
    }
    @media screen and (max-width: 1280px){
      flex-wrap: wrap;
      gap: 2vh 0;
      h2 {
        display: block;
        font-size: 1em;
        width: 100%;
        font-weight: 400;
        margin-top: 25px;
        &:first-of-type{
          margin-top: 0;
        }
      }
      .timing,
      .responsive{
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .label{
          max-width: fit-content;
        }
      }
      .responsive{
        .label{
          display: none;
        }
        .label.mobile-label{
          display: block;
        }
      }
      .technos{
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 3vw;
      }
      .label{
        font-size: 0.9em;
      }
    }
  }
  @media screen and (max-width: 1024px){
    .labels,
    video,
    img,
    .project-description{
      width: 85vw;
    }
  }
  @media screen and (max-width: 730px){
    .labels,
    video,
    img,
    .project-description{
      width: 92vw;
    }
  }
  @media screen and (max-width: 480px){
    .labels,
    video,
    img,
    .project-description{
      width: 96vw;
      font-size: 0.9em;
    }
    .labels{
      gap: 1vh 0;
      margin-bottom: 8vh;
      h2{
        font-size: 1.1em;
      }
    } 
  }
</style>