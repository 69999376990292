<template>
  <section class="contact">
    <h1>ME CONTACTER</h1>
    <p>Si vous avez, une question, un message, un projet / une offre à me partager, n'hésitez pas à le faire via mon formulaire de contact ci dessous. Pour l'instant, j'ai le temps de lire tout les messages que je reçois, alors, c'est le bon moment pour me contacter.</p>
    <form action="https://formspree.io/f/xnqkwjzz" class="contact-form" method="POST">
        <div class="input-container">
          <label for="lastname">Nom</label>
          <input type="text" name="contact_lastname" id="contact_lastname">
        </div>
        <div class="input-container">
          <label for="firstname">Prénom</label>
          <input type="text" name="contact_firstname" id="contact_firstname">
        </div>
        <div class="input-container">
          <label for="firstname">Votre mail</label>
          <input type="email" name="contact_mail" id="contact_mail">
        </div>
        <div class="input-container">
          <label for="message">Message</label>
          <textarea type="text" name="contact_message" id="contact_message"></textarea>
        </div>
        <button type="submit" class="cta">Envoyer</button>
      </form>
  </section>
</template>

<script>
export default{
  name: 'ContactView',
  methods: {
    scrollTop(duration){
      const scrollStep = -window.scrollY / (duration / 15);
      const scrollInterval = setInterval(function () {
        if (window.scrollY !== 0) {
          window.scrollBy(0, scrollStep);
        } else {
          clearInterval(scrollInterval);
        }
      }, 15);
    }
  },
  mounted() {
    this.scrollTop(400);
  },
}
</script>

<style lang="scss" scoped>

  .contact{
    p{
      width: 80%;
      margin: 0 auto;
    }
  }

  form{
    margin-top: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10%;
    max-width: 800px;
    width: 70vw;
    flex-wrap: wrap;
    .input-container{
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-bottom: 3vh;
      &:first-of-type,
      &:nth-of-type(2){
        width: 45%;
        @media screen and (max-width: 800px){
          width: 100%;
        }
      }
      label{
        font-size: 1.2em;
      }
      input{
        padding: 12px 20px;
        border-radius: 8px;
        color: var(--dark-color);
      }
      textarea{
        padding: 15px;
        height: 20vh;
        border-radius: 8px;
        color: var(--dark-color);
        resize: none;
      }
    }
  }

  
  
  @media screen and (max-width: 730px){
    
    .contact{
      p{
        width: 90vw;
        font-size: 0.9em;
      }
    }

    form .input-container label{
      font-size: 1em;
    }
  }
  
  @media screen and (max-width: 730px){
    form {
      width: 80vw;
    }
  }
  
  @media screen and (max-width: 480px){
    form {
      width: 92vw;
    }
  }
</style>