<template>
    <header>
      <img src="../assets/pp.webp" alt="photo de Téo Casanova">
      <div class="header-text">
        <span>Développeur web</span>
        <span>{{ getAge(new Date(1997, 9, 1)) }} ans</span>
        <span>Vue.js / Node.js</span>
      </div>
    </header>
</template>

<script>
export default {
    name: 'Header',
    methods: {
      getAge(date) { 
          var diff = Date.now() - date.getTime();
          var age = new Date(diff); 
          return Math.abs(age.getUTCFullYear() - 1970);
      }
    }
}

</script>

<style lang="scss" scoped>

header{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 4vw;
  padding: 8vh 0;
  .header-text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 15px;
    h1, span{
      font-weight: 500;
    }
  }
  @media screen and (max-width: 1024px){
    img{
      width: 150px;
      height: 150px;
    }
  }
  @media screen and (max-width: 730px){
    img{
      width: 100px;
      height: 100px;
    }
  }
  @media screen and (max-width: 480px){
    padding: 4vh 0;
    flex-direction: column;
    img{
      width: 75px;
      height: 75px;
    }
    .header-text{
      align-items: center;
      gap: 15px;
      h1, span{
        font-size: 1.1em;
        text-align: center;
      }
    }
  }
}
</style>