import { createStore } from "vuex";

export default createStore({
   state: {
      allProjects: [
         {
            id: 11,
            title: "Restaurant Administrable",
            category: "site web",
            timing: "En cours",
            labels: ["Vue.js", "Scss", "Axios", "Node.js", "Sequelize"],
            responsive: 1,
            description:
               "Ce projet à pour but de présenter des restaurants français en mettant en avant leur concept, leur carte, des photos, un espace de réservation, et une partie contact présentant l’emplacement du restaurant ainsi que ses coordonnées et différents réseaux sociaux.<br/><br/>La partie réservation permet au client d'envoyer une demande de réservation qui sera pré confirmée automatiquement en fonction des places disponibles dans le restaurant ce soir la. Le restaurateur recevra donc la réservation sur la partie administration du site.<br/><br/>La partie administration à plusieurs fonctionnalités :<br/>- Changer les textes du site,<br/>- Changer les couleurs du site,<br/>- Changer la devise du site,<br/>- Ajouter et retirer des plats de la carte,<br/>- Recevoir les réservations planifiées<br/><br/>Le site est responsive car une importante partie des internautes cherchant un restaurant son sur leurs smartphones.<br/><br/>Il est fluide car il est basé sur des technologies récentes, Vue.js et Node.js.",
            creation_date: "2023-06-26 12:49:17",
         },
         {
            id: 10,
            title: "Type Train",
            category: "site web",
            timing: "En cours",
            labels: ["Vue.js", "Scss", "Axios", "Node.js", "Sequelize"],
            responsive: 1,
            description:
               "Ce projet représente une application web dédiée à l'amélioration de votre vitesse de frappe au clavier. Vous y trouverez des conseils et astuces essentiels pour développer cette compétence, notamment l'art de taper sans regarder le clavier et la disposition idéale des doigts.<br/><br/>L'objectif de cette application est de vous permettre de mesurer votre vitesse de frappe et de la comparer avec d'autres utilisateurs. Vous pourrez ainsi évaluer votre progression au fil du temps, en observant les améliorations dans votre vitesse de frappe.<br/><br/>Il est important de noter que ce projet est un \"side project\" pour moi, ce qui signifie qu'il est occasionnellement mis en pause pour des projets de plus grande envergure. Cependemment, mon engagement envers cette application persiste, et je continuerai à l'améliorer quand l'occasion se présentera.",
            creation_date: "2023-06-25 12:52:16",
         },
         {
            id: 9,
            title: "Gîte de Moisson",
            category: "site web",
            timing: "Terminé",
            labels: ["Wordpress"],
            responsive: 1,
            description:
               "Ce projet consiste en la création d'un site web réalisé au sein de l'entreprise Novanum, avec pour objectif de présenter le Gîte communal de la ville de Moisson. J'ai débuté le processus en élaborant une maquette graphique qui m'a permis de définir le contenu à inclure ainsi que sa mise en page.<br/><br/>Ce site présente de manière détaillée le gîte, son environnement pittoresque, les tarifs de réservation, et offre une section \"Contact\" permettant aux visiteurs d'envoyer des réservations ou de formuler d'autres demandes à la personne en charge de l'établissement.<br/><br/>Notons que ce site a été entièrement conçu à l'aide de Wordpress, ce qui le rend aisément administrable par son propriétaire.",
            creation_date: "2023-06-24 12:52:16",
         },
         {
            id: 8,
            title: "Little rock",
            category: "maquette",
            timing: "Terminé",
            labels: ["Figma"],
            responsive: 0,
            description:
               "Ce projet constitue une refonte de site web. Il s'agit d'une maquette web interactive élaborée à l'aide du logiciel Figma.<br/><br/>En constatant que le site n'avait pas connu de modifications depuis un certain temps, et que son design montrait des signes de vieillissement, j'ai entrepris de lui insuffler un vent de fraîcheur. J'ai ainsi repensé sa structure et son esthétique pour le mettre au goût du jour.",
            creation_date: "2023-06-23 12:52:16",
         },
         {
            id: 7,
            title: "Planète diamant",
            category: "maquette",
            timing: "Terminé",
            labels: ["Figma"],
            responsive: 0,
            description:
               "Ce projet constitue une refonte de site web. Il s'agit d'une maquette web interactive élaborée à l'aide du logiciel Figma.<br/><br/>En constatant que le site n'avait pas connu de modifications depuis un certain temps, et que son design montrait des signes de vieillissement, j'ai entrepris de lui insuffler un vent de fraîcheur. J'ai ainsi repensé sa structure et son esthétique pour le mettre au goût du jour.",
            creation_date: "2023-06-22 12:52:16",
         },
         {
            id: 6,
            title: "Learn Web Dev",
            category: "site web",
            timing: "Terminé",
            labels: ["HTML", "CSS", "JS", "PHP", "MySQL"],
            responsive: 1,
            description:
               "Ce projet a pour vocation de promouvoir mes services en tant que freelance, spécialisé dans la conception de sites web pour les particuliers.<br/><br/>Pour ce faire, j'ai tout d'abord créé une maquette graphique, destinée à définir à la fois l'aspect visuel et les informations à inclure sur le site. Par la suite, cette maquette a été concrétisée en un site web complet, offrant aux visiteurs la possibilité de découvrir mon approche de travail, mes projets récents, et de me contacter au moyen d'un formulaire dédié.<br/><br/>Il est essentiel de noter que ce site est conçu de manière responsive, ce qui signifie qu'il s'adapte de façon optimale aux différents dispositifs, que ce soit un ordinateur personnel, une tablette, ou un smartphone.",
            creation_date: "2023-06-21 12:52:16",
         },
         {
            id: 5,
            title: "Mon activité",
            category: "site web",
            timing: "Terminé",
            labels: ["XML", "CSS", "JS", "C#", ".NET"],
            responsive: 1,
            description:
               "Ce projet représente une section du site de l'entreprise Novanum, dédiée à la saisie des heures de travail effectuées par les employés au cours de leurs journées.<br/><br/>Pour concrétiser cette section, nous avons commencé par élaborer une maquette graphique, créée par un graphiste, afin de définir l'aspect visuel souhaité pour cette fonctionnalité. Par la suite, j'ai collaboré avec un autre développeur pour intégrer cette maquette et mettre en place la section.<br/><br/>L'objectif à l'avenir est d'utiliser cette section pour mieux comprendre comment chaque employé consacre son temps de travail. Cette information permettra à l'entreprise de prendre des décisions plus éclairées.<br/><br/>Un point important à noter est que cette section est conçue de manière responsive, ce qui signifie qu'elle peut être utilisée aussi bien depuis un ordinateur personnel que depuis un téléphone, garantissant ainsi une grande flexibilité d'utilisation.",
            creation_date: "2023-06-20 12:52:16",
         },
         {
            id: 4,
            title: "Assistance technique",
            category: "site web",
            timing: "Terminé",
            labels: ["XML", "CSS", "JS", "C#", ".NET"],
            responsive: 1,
            description:
               "Ce site, ou plus précisément cette section du site, est la propriété de l'entreprise Novanum, où j'ai eu l'opportunité de développer cette fonctionnalité.<br/><br/>Dans le cadre de ce projet, une maquette graphique a été élaborée par le graphiste de l'entreprise, que j'ai ensuite intégrée. L'objectif de cette section est de permettre à l'entreprise de gérer efficacement les besoins et problématiques de ses clients.<br/><br/>Pour ce faire, les clients se connectent à leur compte et créent un ticket pour exprimer leurs besoins. Ces tickets sont ensuite directement transmis à l'équipe de développeurs. De plus, un chat intégré permet une communication fluide, permettant ainsi aux développeurs de solliciter davantage d'informations si nécessaire.<br/><br/>Un point important à noter est que cette section est conçue de manière responsive, ce qui signifie qu'elle peut être consultée depuis n'importe quel appareil, offrant une grande flexibilité d'accès pour les clients de l'entreprise.",
            creation_date: "2023-06-19 12:52:16",
         },
         {
            id: 3,
            title: "CV en ligne",
            category: "site web",
            timing: "Terminé",
            labels: ["HTML", "CSS", "JS", "PHP", "MySQL"],
            responsive: 1,
            description:
               "Ce projet constitue mon ancien portfolio, conçu dans le but de mettre en lumière mes compétences en développement web. À l'époque, je commençais également à explorer le domaine du design et l'aspect UX/UI des sites web.<br/><br/>Un point essentiel de ce projet est son caractère responsive, ce qui signifie qu'il a été développé pour être consulté de manière optimale depuis n'importe quel appareil, garantissant ainsi une expérience utilisateur fluide et adaptée, que ce soit sur un ordinateur, une tablette ou un téléphone.",
            creation_date: "2023-06-18 12:52:16",
         },
         {
            id: 2,
            title: "Sophrologie Bucchini",
            category: "site web",
            timing: "Terminé",
            labels: ["HTML", "CSS", "JS", "PHP", "MySQL"],
            responsive: 1,
            description:
               "Ce projet représente un site web dédié à la mise en avant des compétences et prestations de sophrologie de Cécile Bucchini. Il s'agit d'un site vitrine qui offre aux visiteurs la possibilité de la contacter et d'en apprendre davantage sur son parcours et son expertise.<br/><br/>Notons que ce site a été développé de manière à être responsive, ce qui signifie qu'il est accessible et consultable de manière optimale sur divers appareils, notamment les tablettes et les téléphones. Cela garantit une expérience fluide pour tous les visiteurs, quel que soit l'appareil qu'ils utilisent.",
            creation_date: "2023-06-17 12:52:16",
         },
         {
            id: 1,
            title: "MNTN",
            category: "site web",
            timing: "Terminé",
            labels: ["HTML", "CSS", "JS", "Gsap", "Scss"],
            responsive: 1,
            description:
               "Ce site représente l'un de mes premiers projets, mettant en avant une agence de randonnée. Pour sa conception, j'ai utilisé une maquette trouvée en ligne et me suis consciencieusement investi dans l'intégration, y ajoutant des effets au défilement de la page grâce à la bibliothèque Gsap.<br/><br/>De plus, j'ai pris l'initiative de créer un design responsive pour ce site, me donnant ainsi l'opportunité de m'exercer dans l'utilisation des médias-queries CSS. Cela m'a permis de développer mes compétences et de garantir une expérience utilisateur optimale, quel que soit l'appareil utilisé pour consulter le site.",
            creation_date: "2023-06-16 12:52:16",
         },
      ],
      lastProjects: [
         {
            id: 11,
            title: "Restaurant Administrable",
            category: "site web",
            timing: "En cours",
            labels: ["Vue.js", "Scss", "Axios", "Node.js", "Sequelize"],
            responsive: 1,
            description:
               "Ce projet à pour but de présenter des restaurants français en mettant en avant leur concept, leur carte, des photos, un espace de réservation, et une partie contact présentant l’emplacement du restaurant ainsi que ses coordonnées et différents réseaux sociaux.<br/><br/>La partie réservation permet au client d'envoyer une demande de réservation qui sera pré confirmée automatiquement en fonction des places disponibles dans le restaurant ce soir la. Le restaurateur recevra donc la réservation sur la partie administration du site.<br/><br/>La partie administration à plusieurs fonctionnalités :<br/>- Changer les textes du site,<br/>- Changer les couleurs du site,<br/>- Changer la devise du site,<br/>- Ajouter et retirer des plats de la carte,<br/>- Recevoir les réservations planifiées<br/><br/>Le site est responsive car une importante partie des internautes cherchant un restaurant son sur leurs smartphones.<br/><br/>Il est fluide car il est basé sur des technologies récentes, Vue.js et Node.js.",
            creation_date: "2023-06-26 12:49:17",
         },
         {
            id: 10,
            title: "Type Train",
            category: "site web",
            timing: "En cours",
            labels: ["Vue.js", "Scss", "Axios", "Node.js", "Sequelize"],
            responsive: 1,
            description:
               "Ce projet est une application web permettant de s'entrainer à taper au clavier le plus rapidement possible.<br/>Pour cela, il y a plusieurs astuces comme : ne pas regarder le clavier lorsque l'on tape, positionner ses doigts d'une certaines manière,...<br/>Le but étant de permettre aux personnes de comparer le vitesse de frappe avec les autres. Il y a aussi une progression permettant de voir quelles sont les évolutions.<br/>Ce projet est un side project, et par conséquent, est souvent mis en pause au profit de projets plus importants.",
            creation_date: "2023-06-25 12:52:16",
         },
         {
            id: 9,
            title: "Gîte de Moisson",
            category: "site web",
            timing: "Terminé",
            labels: ["Wordpress"],
            responsive: 1,
            description:
               "Ce projet est un site web effectué dans l'entreprise Novanum visant à présenter le Gîte communal de la ville de Moisson.<br/>J'ai d'abord commencé par faire une maquette graphique me permettant de connaitre les informations à y mettre et la disposition de ces dernières.<br/>Ce site présente donc le gîte, l'environnement dans le quel il est, les tarifs de réservation, et une partie contact permettant d'envoyer une réservation ou tout autre demande à la personne s'occupant de l'établissement.<br/>Étant donné que ce site a été conçu entièrement à l'aide de Wordpress, il est facilement administrable par son propriétaire.",
            creation_date: "2023-06-24 12:52:16",
         },
      ],
      actualProject: [],
      allReco: [],
   },
   getters: {},
   mutations: {
      getProject(state, project_id) {
         state.actualProject = [];
         state.allProjects.forEach((project) => {
            if (project.id == project_id) {
               state.actualProject = project;
            }
         });
      },
   },
   actions: {},
   modules: {},
});
