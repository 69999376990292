<template>
  <footer>
    <!-- SITE MAP -->
    <div class="site-map">
      <h3>Liens utiles</h3>
      <a href="/" data-replace="Accueil"><span>Accueil</span></a>
      <a href="/portfolio" data-replace="Projets"><span>Projets</span></a>
      <a href="/about" data-replace="A propos"><span>A propos</span></a>
      <a href="/contact" data-replace="Contact"><span>Contact</span></a>
    </div>
    <!-- RÉSEAUX SOCIAUX -->
    <div class="social-medias">
      <h3>Me contacter</h3>
      <a href="https://www.linkedin.com/in/t%C3%A9o-casanova-33632613b/" class="line">
        <img src="../assets/social_media_1.png" alt="linkedIn">
        <span>Téo Casanova</span>
      </a>
    </div>
    <div class="siret">SIRET : 84020946400022</div>
    <div class="bottom-part">All rights reserved Téo Casanova | Designed by Téo Casanova</div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  footer{
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-top: 6vh;
    margin-top: 10vh;
    background-color: var(--light-color);
    h3{
      color: var(--dark-color);
      margin-bottom: 25px;
      font-size: 1.3em;
      font-weight: 800;
    }
    .social-medias,
    .site-map{
      width: 45%;
    }
    .site-map{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      a{
        font-weight: 600;
        transition: .3s;
        overflow: hidden;
        &, span{
          position: relative;
          display: inline-block;
          transition: .4s;
          color: var(--dark-color);
        }
        span{
          padding: 3px 0;
          transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
        }
        &:hover span{
          transform: translateX(-200%);
        }
        &::before,
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          left: 0;
          transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
        }
        &::after{
          content: attr(data-replace);
          top: 0;
          transform-origin: 100% 50%;
          transform: translateX(200%);
        }
        &::before{
          background-color: var(--dark-color);
          height: 2px;
          bottom: 0;
          transform-origin: 100% 50%;
          transform: scaleX(0);
        }
        &:hover{
          &::after{
            transform: translate(0, 0);
          }
          &::before{
            transform-origin: 0% 50%;
            transform: scaleX(1);
          }
        }
      }
    }
    .social-medias{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 25px;
      span, a{
        color: var(--dark-color);
        font-weight: 600;
        transition: .3s;
      }
      .line{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        cursor: pointer;
      }
    }
    .social-medias .line:hover span{
      transform: translateX(5px);
    }
    .siret,
    .bottom-part{
      width: 100%;
      max-width: 100%;
      text-align: center;
      background-color: var(--dark-color);
      padding: 12px 0;
    }
    .siret{
      background-color: #345874;
      padding: 8px 0;
      font-size: .8em;
      margin-top: 4vh;
    }
  }

  @media screen and (max-width: 1024px){
  footer{
    padding-top: 4vh;
    h3{
      margin-bottom: 2vh;
    font-size: 1.2em;

    }
    .site-map a,
    .social-medias .line{
        span{
          font-size: 0.9em;
        }
    }
    .bottom-part,
    .site-map a::after{
          font-size: 0.9em;
    }
  }
}

@media screen and (max-width: 730px){
  footer{
    justify-content: center;
    h3{
      margin-bottom: 2vh;
    }
    .site-map{
      width: 40%;
    }
    .social-medias{
      width: 50%;
    }
  }
}

@media screen and (max-width: 480px){
  footer {
    flex-direction: column-reverse;
    .site-map,
    .social-medias{
      width: 75%;
      margin: 8vh auto 0 auto;
    }
    .site-map a span{
      font-size: 1em;
    }
    .social-medias{
      margin-top: 0;
    }
    .bottom-part{
      order: -2;
    }
    .siret{
      order: -1;
    }
  }
}
</style>
