<template>
  <main class="home">
    <Header></Header>

    <!-- DERNIERS PROJETS -->
    <section class="last_projects">
      <h1>DERNIERS PROJETS</h1>
      <div class="cards-container">
        <router-link v-for="(project, index) in this.$store.state.lastProjects" :to="'/project/'+project.id" :key="index">
          <!-- {{ project }} -->
          <span>{{ project.title }}</span>
          <img v-if="this.screenWidth >= 730" :src="require(`@/assets/thumbnails/Desktop/project_thumbnail_desktop_${project.id}.webp`)" :alt="'miniature du projet' + project.title">
          <img v-if="this.screenWidth < 730 && this.screenWidth >= 480" :src="require(`@/assets/thumbnails/Tablet/project_thumbnail_tablet_${project.id}.webp`)" :alt="'miniature du projet' + project.title">
          <img v-if="this.screenWidth < 480" :src="require(`@/assets/thumbnails/Smartphone/project_thumbnail_smartphone_${project.id}.webp`)" :alt="'miniature du projet' + project.title">
        </router-link>
      </div>
      <button class="cta" @click="this.$router.push('/portfolio')">Voir plus</button>
    </section>
    
    <!-- COMPÉTENCES ET ENVIRONNEMENT -->
    <section class="skills-environnement">
      <h1>COMPÉTENCES ET ENVIRONNEMENT</h1>
      <h2>STACK ACTUELLE</h2>
      <div class="actual-stack">
        <div class="techno-container" data-replace="Vue.js">
          <img v-if="this.screenWidth >= 730" src="../assets/vuejs_desktop.webp" alt="logo de vue.js">
          <img v-if="this.screenWidth < 730 && this.screenWidth >= 480" src="../assets/vuejs_tablet.webp" alt="logo de vue.js">
          <img v-if="this.screenWidth < 480" src="../assets/vuejs_smartphone.webp" alt="logo de vue.js">
        </div>
        <div class="techno-container" data-replace="Sass">
          <img v-if="this.screenWidth >= 730" src="../assets/sass_desktop.webp" alt="logo de sass">
          <img v-if="this.screenWidth < 730 && this.screenWidth >= 480" src="../assets/sass_tablet.webp" alt="logo de sass">
          <img v-if="this.screenWidth < 480" src="../assets/sass_smartphone.webp" alt="logo de sass">
        </div>
        <div class="techno-container" data-replace="Axios">
          <img v-if="this.screenWidth >= 730" src="../assets/axios_desktop.webp" alt="logo de axios">
          <img v-if="this.screenWidth < 730 && this.screenWidth >= 480" src="../assets/axios_tablet.webp" alt="logo de axios">
          <img v-if="this.screenWidth < 480" src="../assets/axios_smartphone.webp" alt="logo de axios">
        </div>
        <div class="techno-container" data-replace="Node.js">
          <img v-if="this.screenWidth >= 730" src="../assets/node_desktop.webp" alt="logo de node.js">
          <img v-if="this.screenWidth < 730 && this.screenWidth >= 480" src="../assets/node_tablet.webp" alt="logo de node.js">
          <img v-if="this.screenWidth < 480" src="../assets/node_smartphone.webp" alt="logo de node.js">
        </div>
        <div class="techno-container" data-replace="Sequelize">
          <img v-if="this.screenWidth >= 730" src="../assets/sequelize_desktop.webp" alt="logo de sequelize">
          <img v-if="this.screenWidth < 730 && this.screenWidth >= 480" src="../assets/sequelize_tablet.webp" alt="logo de sequelize">
          <img v-if="this.screenWidth < 480" src="../assets/sequelize_smartphone.webp" alt="logo de sequelize">
        </div>
      </div>
      <h2>ENVIRONNEMENT</h2>
      <div class="environnement">
        <div class="techno-container" data-replace="Windows">
            <img v-if="this.screenWidth >= 730" src="../assets/windows_desktop.webp" alt="logo de windows">
              <img v-if="this.screenWidth < 730 && this.screenWidth >= 480" src="../assets/windows_tablet.webp" alt="logo de windows">
            <img v-if="this.screenWidth < 480" src="../assets/windows_smartphone.webp" alt="logo de windows">
        </div>
        <div class="techno-container" data-replace="Chrome">
            <img v-if="this.screenWidth >= 730" src="../assets/chrome_desktop.webp" alt="logo de chrome">
              <img v-if="this.screenWidth < 730 && this.screenWidth >= 480" src="../assets/chrome_tablet.webp" alt="logo de chrome">
            <img v-if="this.screenWidth < 480" src="../assets/chrome_smartphone.webp" alt="logo de chrome">
        </div>
        <div class="techno-container" data-replace="Vscode">
            <img v-if="this.screenWidth >= 730" src="../assets/vscode_desktop.webp" alt="logo de vscode">
              <img v-if="this.screenWidth < 730 && this.screenWidth >= 480" src="../assets/vscode_tablet.webp" alt="logo de vscode">
            <img v-if="this.screenWidth < 480" src="../assets/vscode_smartphone.webp" alt="logo de vscode">
        </div>
        <div class="techno-container" data-replace="Figma">
            <img v-if="this.screenWidth >= 730" src="../assets/figma_desktop.webp" alt="logo de figma">
              <img v-if="this.screenWidth < 730 && this.screenWidth >= 480" src="../assets/figma_tablet.webp" alt="logo de figma">
            <img v-if="this.screenWidth < 480" src="../assets/figma_smartphone.webp" alt="logo de figma">
        </div>
        <div class="techno-container" data-replace="Dribbble">
            <img v-if="this.screenWidth >= 730" src="../assets/dribbble_desktop.webp" alt="logo de dribbble">
              <img v-if="this.screenWidth < 730 && this.screenWidth >= 480" src="../assets/dribbble_tablet.webp" alt="logo de dribbble">
            <img v-if="this.screenWidth < 480" src="../assets/dribbble_smartphone.webp" alt="logo de dribbble">
        </div>
      </div>
      <button class="cta" @click="this.$router.push('/portfolio')">Voir mes projets</button>
    </section>

  </main>
</template>

<script>
import Header from "@/components/Header";

export default {
  name: 'HomeView',
  data(){
    return{
      screenWidth: "",
    }
  },
  methods: {
    scrollTop(duration){
      const scrollStep = -window.scrollY / (duration / 15);
      const scrollInterval = setInterval(function () {
        if (window.scrollY !== 0) {
          window.scrollBy(0, scrollStep);
        } else {
          clearInterval(scrollInterval);
        }
      }, 15);
    }
  },
  mounted() {
    this.screenWidth = window.innerWidth
    window.addEventListener('resize', ()=>{
      this.screenWidth = window.innerWidth
    });
    this.scrollTop(400);
  },
  components: {
    Header
  }
}
</script>

<style lang="scss" scoped>

  section.last_projects{
    .cards-container{
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 96vw;
      gap: 2vw;
      padding: 3vh 0;
      &::-webkit-scrollbar{
        height: 10px;
      }
      &::-webkit-scrollbar-thumb{
        border: none;
        background-color: var(--light-color);
      }
      &::-webkit-scrollbar-track{
        background-color: #ebfbff1f;
      }
      a{
        width: fit-content;
        height: fit-content;
        min-width: 440px;
        position: relative;
        transition: .4s;
        overflow: hidden;
        border-radius: 15px;
        &:hover{
          transform: translateY(-5px);
          span {
            transform: translateY(0);
          }
        }
        span{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          padding: 10px 0;
          background-color: rgba(235, 251, 255, .8);
          text-align: center;
          text-transform: uppercase;
          color: var(--dark-color);
          font-weight: 800;
          z-index: 50;
          transform: translateY(-100%);
          transition: .4s;
        }
        img{
          width: 440px;
          min-width: 440px;
          height: 247.5px;
          min-height: 247.5px;
          border-radius: 15px;
        }
      }
    }
  }
  @media screen and (max-width: 1500px){
    section.last_projects{
      .cards-container{
        justify-content: space-between;
      }
    }
  }
  @media screen and (max-width: 1433px){
    .cards-container{
      overflow-x: scroll;
    }
  }

  @media screen and (max-width: 730px){
    section.last_projects .cards-container a{
      min-width: 340px;
      &:hover{
        transform: translateY(0);
      }
      span{
        position: static;
        display: block;
        width: 340px;
        transform: translateY(0);
        padding: 6px 0;
        font-size: 0.8em;
      }
      img{
          width: 340px;
          min-width: 340px;
          height: 191.25px;
          min-height: 191.25px;
          border-radius: 0 0 15px 15px;
      }
    }
  }
  
  @media screen and (max-width: 480px){
    section.last_projects .cards-container a{
      min-width: 300px;
      height: 168.75px;
      img{
          width: 300px;
          min-width: 300px;
          height: 168.75px;
          min-height: 168.75px;
      }
    }
  }
  
  
  section.skills-environnement{
      h2{
        margin-top: 20px;
      }
      
    margin-bottom: -10vh;
    .environnement,
    .actual-stack{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      height: 700px;
      .techno-container{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, .7);
        position: relative;
        overflow: hidden;
        width: calc(100% / 3);
        filter: grayscale(100%);
        transition: .4s;
        &:hover{
          filter: grayscale(0%);
        }
        &::after{
          content: attr(data-replace);
          position: absolute;
          bottom: -50px;
          left: 0;
          width: 100%;
          text-align: center;
          font-size: 1.3em;
          color: var(--light-color);
          transition: .4s;
        }
        &:hover::after{
          bottom: 50px;
        }
        &:last-child,
        &:nth-child(4){
          width: 50%;
          border-top: 1px solid var(--dark-color);
        }
        &:first-child,
        &:nth-child(2),
        &:nth-child(4){
          width: calc((100% / 3) - 1px);
          border-right: 1px solid var(--dark-color);
        }
        &:nth-child(4){
          width: calc(50% - 1px);
        }
      }
    }
    .cta{
      margin-bottom: 25px;
    }
    @media screen and (max-width: 730px){
      .environnement,
      .actual-stack{
        height: 350px;
        .techno-container{
        filter: grayscale(0%);
          &::after{
            bottom: 15px;
          }
          &:hover::after{
            bottom: 15px;
          }
        }
      }
    }
    @media screen and (max-width: 480px){
      .environnement,
      .actual-stack{
        height: 350px;
      }
    }
  }

  section.recommendations{
    .reco-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 4vh;
      .reco{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        gap: 40px;
        border-radius: 15px;
        padding: 50px;
        background-color: var(--light-color);
        width: 80vw;
        margin: 0 auto;
        p{
          font-size: 1em;
          font-weight: 400;
          color: var(--dark-color);
        }
      }
    }
    .add-recommandation{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10%;
      width: 70vw;
      max-width: 800px;
      flex-wrap: wrap;
      .input-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 3vh;
        &:first-of-type,
        &:nth-of-type(2){
          width: 45%;
        }
        label{
          font-size: 1.2em;
        }
        input{
          padding: 12px 20px;
          border-radius: 8px;
          color: var(--dark-color);
        }
        textarea{
          padding: 15px;
          height: 20vh;
          border-radius: 8px;
          color: var(--dark-color);
        }
      }
    }
  }
</style>
