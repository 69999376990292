<template>
  <div class="main-container">
    <nav>
      <ul>
        <li>
          <router-link to="/" data-replace="ACCUEIL">
            <svg class="home_icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 19V10C4 9.68333 4.07083 9.38333 4.2125 9.1C4.35417 8.81667 4.55 8.58333 4.8 8.4L10.8 3.9C11.15 3.63333 11.55 3.5 12 3.5C12.45 3.5 12.85 3.63333 13.2 3.9L19.2 8.4C19.45 8.58333 19.6458 8.81667 19.7875 9.1C19.9292 9.38333 20 9.68333 20 10V19C20 19.55 19.8042 20.0208 19.4125 20.4125C19.0208 20.8042 18.55 21 18 21H15C14.7167 21 14.4792 20.9042 14.2875 20.7125C14.0958 20.5208 14 20.2833 14 20V15C14 14.7167 13.9042 14.4792 13.7125 14.2875C13.5208 14.0958 13.2833 14 13 14H11C10.7167 14 10.4792 14.0958 10.2875 14.2875C10.0958 14.4792 10 14.7167 10 15V20C10 20.2833 9.90417 20.5208 9.7125 20.7125C9.52083 20.9042 9.28333 21 9 21H6C5.45 21 4.97917 20.8042 4.5875 20.4125C4.19583 20.0208 4 19.55 4 19Z" fill="#EBFBFF"/>
            </svg>
              <span>ACCUEIL</span>
            </router-link>
        </li>
        <li>
          <router-link to="/portfolio" class="portfolio-link" data-replace="PROJETS">
            <svg class="project_icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 22C11.45 22 10.9792 21.8042 10.5875 21.4125C10.1958 21.0208 10 20.55 10 20H14C14 20.55 13.8042 21.0208 13.4125 21.4125C13.0208 21.8042 12.55 22 12 22ZM9 19C8.71667 19 8.47917 18.9042 8.2875 18.7125C8.09583 18.5208 8 18.2833 8 18C8 17.7167 8.09583 17.4792 8.2875 17.2875C8.47917 17.0958 8.71667 17 9 17H15C15.2833 17 15.5208 17.0958 15.7125 17.2875C15.9042 17.4792 16 17.7167 16 18C16 18.2833 15.9042 18.5208 15.7125 18.7125C15.5208 18.9042 15.2833 19 15 19H9ZM8.25 16C7.1 15.3167 6.1875 14.4 5.5125 13.25C4.8375 12.1 4.5 10.85 4.5 9.5C4.5 7.41667 5.22917 5.64583 6.6875 4.1875C8.14583 2.72917 9.91667 2 12 2C14.0833 2 15.8542 2.72917 17.3125 4.1875C18.7708 5.64583 19.5 7.41667 19.5 9.5C19.5 10.85 19.1625 12.1 18.4875 13.25C17.8125 14.4 16.9 15.3167 15.75 16H8.25Z" fill="#EBFBFF"/>
            </svg>
              <span>PROJETS</span>
            </router-link>
        </li>
        <li>
          <router-link to="/about" data-replace="A PROPOS">
            <svg class="journey_icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 21C7.9 21 6.95833 20.6083 6.175 19.825C5.39167 19.0417 5 18.1 5 17V8.825C4.41667 8.60833 3.9375 8.24583 3.5625 7.7375C3.1875 7.22917 3 6.65 3 6C3 5.16667 3.29167 4.45833 3.875 3.875C4.45833 3.29167 5.16667 3 6 3C6.83333 3 7.54167 3.29167 8.125 3.875C8.70833 4.45833 9 5.16667 9 6C9 6.65 8.8125 7.22917 8.4375 7.7375C8.0625 8.24583 7.58333 8.60833 7 8.825V17C7 17.55 7.19583 18.0208 7.5875 18.4125C7.97917 18.8042 8.45 19 9 19C9.55 19 10.0208 18.8042 10.4125 18.4125C10.8042 18.0208 11 17.55 11 17V7C11 5.9 11.3917 4.95833 12.175 4.175C12.9583 3.39167 13.9 3 15 3C16.1 3 17.0417 3.39167 17.825 4.175C18.6083 4.95833 19 5.9 19 7V15.175C19.5833 15.3917 20.0625 15.7542 20.4375 16.2625C20.8125 16.7708 21 17.35 21 18C21 18.8333 20.7083 19.5417 20.125 20.125C19.5417 20.7083 18.8333 21 18 21C17.1667 21 16.4583 20.7083 15.875 20.125C15.2917 19.5417 15 18.8333 15 18C15 17.35 15.1875 16.7667 15.5625 16.25C15.9375 15.7333 16.4167 15.375 17 15.175V7C17 6.45 16.8042 5.97917 16.4125 5.5875C16.0208 5.19583 15.55 5 15 5C14.45 5 13.9792 5.19583 13.5875 5.5875C13.1958 5.97917 13 6.45 13 7V17C13 18.1 12.6083 19.0417 11.825 19.825C11.0417 20.6083 10.1 21 9 21Z" fill="#EBFBFF"/>
            </svg>
              <span>A PROPOS</span>
            </router-link>
        </li>
        <li>
          <router-link to="/contact" data-replace="CONTACT">
            <svg class="contact_icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.4 19.4252C4.06667 19.5585 3.75 19.5294 3.45 19.3377C3.15 19.146 3 18.8669 3 18.5002V14.0002L11 12.0002L3 10.0002V5.50022C3 5.13355 3.15 4.85438 3.45 4.66272C3.75 4.47105 4.06667 4.44188 4.4 4.57522L19.8 11.0752C20.2167 11.2585 20.425 11.5669 20.425 12.0002C20.425 12.4335 20.2167 12.7419 19.8 12.9252L4.4 19.4252Z" fill="#EBFBFF"/>
            </svg>
              <span>CONTACT</span>
            </router-link>
        </li>
      </ul>
    </nav>
    <router-view/>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer";

export default{
  name: "App",
  components: {
    Footer
  },
  methods: {
  },
  mounted(){
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*{
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  text-decoration: none;
  outline: none;
  color: var(--light-color);
  font-family: 'Inter', sans-serif;
}

ol{
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 75vw;
    margin: 0 auto;
}

/* width */
::-webkit-scrollbar {
  width: 1.3vw;
}
@media screen and (max-width: 1024px) {
  ::-webkit-scrollbar {
    width: 15px;
  }
}
/* Track */
::-webkit-scrollbar-track {
  background-color: var(--light-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: var(--dark-color);
  border-radius: 4px;
  border: 2px solid var(--light-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: var(--cta-color);
}

.cta{
  color: #111617;
  font-size: 1.1em;
  font-weight: 600;
  padding: 8px 40px;
  border-radius: 50px;
  background-image: linear-gradient(.05turn,var(--dark-color) 50%, var(--cta-color) 50%);
  background-size: 300% 300%;
  background-position: right;
  border: 2px solid var(--cta-color);
  cursor: pointer;
  transition: .4s;
  &:hover{
    background-position: left;
    color: var(--cta-color);
  }
}

@media screen and (max-width: 730px) {
  .cta{
    font-size: 1em;
    padding: 6px 30px;
  }
}

@media screen and (max-width: 480px) {
  .cta{
    font-size: 0.8em;
  }
}

:root{
  --black-color: #142833;
  --dark-color: #234658;
  --light-color: #EBFBFF;
  --cta-color: #F97068;
}

h1{
  font-weight: 900;
  font-size: 2em;
  letter-spacing: 0.1em;
  word-spacing: 12px;
  padding: 25px 0;
  margin: 0 auto;
  width: fit-content;
  text-align: center;
}
h2{
  font-size: 1.4em;
}
h3{
  font-size: 1.2em;
}

section{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  padding-top: 4vh;
}

nav{
  width: 100vw;
  height: 15vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--dark-color);
  z-index: 99;
  box-shadow: 0px 0px 8px 4px var(--light-color);
  ul{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3vw;
    height: 100%;
    li {
      height: 100%;
      width: 25%;
      display: flex;
      align-items: center;
      justify-content: center;
      a{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1vw;
        width: 100%;
        height: 100%;
        opacity: .7;
        font-weight: 600;
        font-size: 1.3em;
        overflow: hidden;
        line-height: 1.2em;
        &, span{
          position: relative;
          // display: inline-block;
          transition: .4s;
        }
        span{
          padding: 3px 0;
          transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
          min-width: max-content;
        }
        &:hover span{
          transform: translateX(-200%);
        }
        &:hover::before{
            transform-origin: 0% 50%;
            transform: scaleX(1);
        }
        &::before,
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          left: 0;
          transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
        }
        &::after{
          content: attr(data-replace);
          bottom: 40%;
          transform-origin: 100% 50%;
          transform: translateX(200%);
          text-align: right;
        }
        &::before{
          background-color: var(--light-color);
          height: 2px;
          bottom: calc(50% - 20px);
          transform-origin: 100% 50%;
          transform: scaleX(0) translateY(50%);
        }
        &.router-link-active,
        &:hover{
          opacity: 1;
          span{
            transform: translateX(-200%);
          }
          &::after{
            transform: translate(0, -2px);
          }
          &::before{
            transform-origin: 0% 50%;
            transform: scaleX(1);
        }
        }
      }
    }
  }
}

#app{
  padding-top: 15vh;
  background-color: var(--dark-color);
  min-height: 100vh;
}

@media screen and (max-width: 730px){
  #app{
    padding-top: 0;
    padding-bottom: 12vh;
  }
  nav{
    top: auto;
    bottom: 0;
    height: 10vh;
    padding-top: 2vh;
    background-color: var(--light-color);
    box-shadow: 0px 1px 8px 0 var(--dark-color);
    ul{
      justify-content: space-between;
      width: 100%;
      height: 100%;
      position: relative;
      gap: 0;
      &::after{
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        background-color: var(--cta-color);
        height: 12vh;
        width: 25%;
        z-index: 5;
        transition: .4s
      }
      li{
        z-index: 10;
        a{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          &::after,
          &::before,
          &:hover::after,
          &:hover::before{
            display: none;
          }
          &:hover span{
            transform: translate(0, 0);
          }
          &.router-link-active,
          &:hover{
            opacity: 1;
            span{
              transform: translateX(0);
            }
            &::after{
              transform: translate(0, 0);
            }
          }
          &.router-link-active{
            svg path{
              fill: var(--light-color);
            }
            span{
              color: var(--light-color);
            }
          }
          svg{
            width: 32px;
            height: 32px;
            path{
              fill: var(--dark-color);
            }
          }
          span{
            font-size: 0.8em;
            color: var(--dark-color);
          }
        }
      }
    }
    ul.home::after{
      left: 0%;
      right: auto;
    }
    ul.portfolio::after,
    ul.projects::after{
      left: 25%;
      right: auto;
    }
    ul.about::after{
      left: auto;
      right: 25%;
    }
    ul.contact::after{
      left: auto;
      right: 0%;
    }
  }
}

@media screen and (max-width: 480px){
  #app{
    
    padding-bottom: 10vh;
  }
  h1{
    font-size: 1.6em;
    padding: 20px 0;
  }
  h2{
    padding: 20px 0;
  }
  section{
    gap: 20px;
    padding-top: 6vh;
  }
  nav{
    padding-top: 0;
    ul{
      &::after{
        height: 10vh;
      }
      li a span{
        display: none;
      }
    }
  }
}


</style>
